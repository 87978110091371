import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchUserVirtualhosts,
    containerBackupsList,
    fetchUserContainers,
    restoreFromBackupUpdateParams,
} from '../../actions/hosting/actions';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreResourceStep from '../../components/Hosting/HostingBackup/RestoreResourceStep';
import RestoreBackupLoader from '../../components/General/RestoreBackupLoader/RestoreBackupWithDatesLoader';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingBackupResourcesStack = createStackNavigator();

function HostingBackupResourcesStackScreen(props) {
    const { route } = props;
    const {
        backups,
        backupsFetchInProgress,
        containers,
        containersFetchInProgress,
    } = useSelector(
        (state) => state.hosting,
    );
    const { currentContainer } = useSelector(
        (state) => state.hostingRestoreFromBackup,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    const containerId = Number.parseInt(route.params.containerId, 10);
    useFocusEffect(useCallback(() => {
        dispatch(fetchUserVirtualhosts(containerId));
        if (containers == null && !containersFetchInProgress) {
            dispatch(fetchUserContainers());
        }
    }, []));
    useEffect(() => {
        if (currentContainer) {
            dispatch(containerBackupsList(currentContainer.id));
        } else if (containers) {
            const container = containers.entities.find(
                (c) => c.id === containerId,
            );
            dispatch(
                restoreFromBackupUpdateParams(
                    {
                        currentContainer: container,
                    },
                    () => dispatch(containerBackupsList(container.id)),
                ),
            );
        }
    }, [containers]);
    useEffect(() => {
        if (backups && backups.entities && backups.entities.length > 0) {
            dispatch(
                restoreFromBackupUpdateParams({
                    restoreDate: backups.entities[0].date,
                }),
            );
        }
    }, [backups]);

    return <HostingBackupResourcesStack.Navigator>
        <HostingBackupResourcesStack.Screen name="HostingBackupResourcesScreen"
            component={
                (backups == null || backupsFetchInProgress)
                    ? RestoreBackupLoader : RestoreResourceStep}
            options={{
                title: 'Хостинг | Мастер восстановления',
                headerTitle: () => <ScreenHeaderTitle
                    title={(currentContainer && currentContainer.name) || 'Идет загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('HostingBackup');
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </HostingBackupResourcesStack.Navigator>;
}

HostingBackupResourcesStackScreen.propTypes = {
    route: PropTypes.object,
};

export default HostingBackupResourcesStackScreen;
