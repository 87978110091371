import React, { useEffect } from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Platform, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import MainScreen from './screens/tabs';
import AuthScreen from './screens/auth';
import OtpScreen from './screens/otp';
import OtpFail from './screens/otpFail';
import CVDSStackScreen from './screens/cvds';
import AuthFailScreen from './screens/authFail';

import MailScreen from './screens/mail';
import LoadingScreen from './screens/loading';
import HostingScreen from './screens/hosting';
import CVDSVMStackScreen from './screens/cvdsVm';
import Notifications from './screens/notifications';
import MailDomainScreen from './screens/mailDomain';
import MailPacketScreen from './screens/mailPacket';
import PartnershipScreen from './screens/partnership';
import CVDSCreateStackScreen from './screens/cvdsCreate';
import MailboxSettingsScreen from './screens/mailboxSettings';
import ChangePasswordScreen from './screens/changePassword';
import FinancePaymentScreen from './screens/financePayment';
import ChangeRequisitesScreen from './screens/changeRequisites';
import FinanceForecastScreen from './screens/financeForecast';
import CVDSVMTariffsStackScreen from './screens/cvdsVmTariffs';
import HostingContainerScreen from './screens/hostingContainer';
import CVDSVMEditVolumeStackScreen from './screens/cvdsEditVolume';
import SendInvoiceToEmailScreen from './screens/sendInvoiceToEmail';
import HostingVirtualhostScreen from './screens/hostingVirtualhost';
import HostingChangeTariffScreen from './screens/hostingChangeTariff';
import HostingDbmsAccessStackScreen from './screens/hostingDbmsAccess';
import CVDSDistributionsStackScreen from './screens/cvdsDistributions';
import CVDSVMEditBandwidthStackScreen from './screens/cvdsVmEditBandwidth';
import CVDSVMProtectedIpsStackScreen from './screens/cvdsVmProtectedIps';
import CVDSVMProtectedWebsitesStackScreen from './screens/cvdsVmProtectedWebsites';
import CVDSBackupScreen from './screens/CvdsBackups/cvdsBackup';
import CVDSBackupDisksScreen from './screens/CvdsBackups/cvdsBackupDisks';
import CVDSBackupMethodsScreen from './screens/CvdsBackups/cvdsBackupMethods';
import CVDSBackupConfigurationScreen from './screens/CvdsBackups/cvdsBackupConfiguration';
import PartnershipWithdrawalScreen from './screens/partnershipWithdrawal';
import FinancePromisedPaymentScreen from './screens/financePromisedPayment';
import HostingCreateVirtualhost from './screens/hostingVirtualhostCreate';
import HostingCreateContainerStackScreen from './screens/hostingCreateContainer';
import RestorePasswordScreen from './screens/restorePassword';
import RestorePasswordDoneScreen from './screens/restorePasswordDone';
import RestorePasswordConfirmScreen from './screens/restorePasswordConfirm';
import RestorePasswordFailScreen from './screens/restorePasswordFail';
import HostingBackupScreen from './screens/HostingBackups/hostingBackup';
import HostingBackupContainerScreen from './screens/HostingBackups/hostingBackupContainer';
import HostingBackupResourcesScreen from './screens/HostingBackups/hostingBackupResources';
import HostingBackupVirtualhostScreen from './screens/HostingBackups/hostingBackupVirtualhost';
import HostingBackupConfigurationScreen from './screens/HostingBackups/hostingBackupConfiguration';
import HostingBackupDbmsScreen from './screens/HostingBackups/hostingBackupDbms';
import TransferScreen from './screens/transfer';
import TransferAcceptScreen from './screens/transferAccept';
import MonitoringStackScreen from './screens/Monitoring/monitoring';
import MonitoringHostingStackScreen from './screens/Monitoring/monitoringHosting';
import MonitoringCvdsStackScreen from './screens/Monitoring/monitoringCvds';
import MonitoringMailStackScreen from './screens/Monitoring/monitoringMail';
import SslScreen from './screens/ssl';
import SSLDetailScreen from './screens/sslDetail';

import { linking } from './linking';
import { financeInfo } from './actions/user/actions';

import NotificationMomentWS from './components/Notifications/NotificationMomentWS';
import NotificationMomentBlock from './components/Notifications/NotificationMomentBlock';

// eslint-disable-next-line react/display-name
const checkAuth = (Component, withRedirectToAuth = true, onAuthorized = () => {}) => (props) => {
    if (Platform.OS === 'web') {
        const authData = useSelector((state) => state.user.auth);
        const financeInfoData = useSelector((state) => state.user.financeInfo);
        const { navigation } = props;

        if (
            authData.inProgress
        || (financeInfoData == null && !authData.isError)
        ) {
            return <LoadingScreen />;
        }
        if (withRedirectToAuth && authData.isError) {
            navigation.navigate('Auth');
            return null;
        }
        if (authData.isAuthorized) {
            onAuthorized(navigation);
        }
    }
    return (
        <React.Fragment>
            <Component {...props} />
            <NotificationMomentWS />
            <View
                style={{
                    position: 'fixed',
                    bottom: 88,
                    width: '100%',
                }}
            >
                <NotificationMomentBlock />
            </View>
        </React.Fragment>
    );
};

const Stack = createStackNavigator();

function AppNavigation() {
    const authData = useSelector((state) => state.user.auth);
    const financeInfoData = useSelector((state) => state.user.financeInfo);
    const dispatch = useDispatch();

    if (Platform.OS === 'web') {
        useEffect(() => {
            if (
                financeInfoData == null
        && !authData.isError
        && !authData.inProgress
        && !authData.isAuthorized
            ) {
                dispatch(financeInfo(null, null, true));
            }
        }, [financeInfoData]);
    }

    return (
        <NavigationContainer linking={linking}>
            <Stack.Navigator
                screenOptions={{ cardStyle: { backgroundColor: '#ffffff' } }}
            >
                <Stack.Screen
                    name="MainStack"
                    component={checkAuth(MainScreen)}
                    options={{
                        title: 'Личный кабинет',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDS"
                    component={checkAuth(CVDSStackScreen)}
                    options={{
                        title: 'Облачные VDS',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSVM"
                    component={checkAuth(CVDSVMStackScreen)}
                    options={{
                        title: 'Облачные VDS',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSCreate"
                    component={checkAuth(CVDSCreateStackScreen)}
                    options={{
                        title: 'Облачные VDS | Создание ВМ',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSVMTariffs"
                    component={checkAuth(CVDSVMTariffsStackScreen)}
                    options={{
                        title: 'Облачные VDS | Изменение тарифа',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSDistributions"
                    component={checkAuth(CVDSDistributionsStackScreen)}
                    options={{
                        title: 'Облачные VDS | Конфигурация ВМ',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSVMEditVolume"
                    component={checkAuth(CVDSVMEditVolumeStackScreen)}
                    options={{
                        title: 'Облачные VDS | Изменение диска',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSVMEditBandwidth"
                    component={checkAuth(CVDSVMEditBandwidthStackScreen)}
                    options={{
                        title: 'Облачные VDS | Изменение полосы для трафика',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSVMProtectedIps"
                    component={checkAuth(CVDSVMProtectedIpsStackScreen)}
                    options={{
                        title: 'Облачные VDS | Защищенные IP-адреса',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSVMProtectedWebsites"
                    component={checkAuth(CVDSVMProtectedWebsitesStackScreen)}
                    options={{
                        title: 'Облачные VDS | Фильтрация по доменам',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSBackup"
                    component={checkAuth(CVDSBackupScreen)}
                    options={{
                        title: 'Облачные VDS | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSBackupDisks"
                    component={checkAuth(CVDSBackupDisksScreen)}
                    options={{
                        title: 'Облачные VDS | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSBackupMethods"
                    component={checkAuth(CVDSBackupMethodsScreen)}
                    options={{
                        title: 'Облачные VDS | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="CVDSBackupConfiguration"
                    component={checkAuth(CVDSBackupConfigurationScreen)}
                    options={{
                        title: 'Облачные VDS | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="Notifications"
                    component={checkAuth(Notifications)}
                    options={{
                        title: 'Уведомления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="Payment"
                    component={checkAuth(FinancePaymentScreen)}
                    options={{
                        title: 'Финансы | Пополнение счета',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="Forecast"
                    component={checkAuth(FinanceForecastScreen)}
                    options={{
                        title: 'Финансы | Ожидаемые списания',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="PromisedPayment"
                    component={checkAuth(FinancePromisedPaymentScreen)}
                    options={{
                        title: 'Финансы | Обещанный платеж',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="SendInvoiceToEmail"
                    component={checkAuth(SendInvoiceToEmailScreen)}
                    options={{
                        title: 'Финансы | Отправка счета на Email',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="Hosting"
                    component={checkAuth(HostingScreen)}
                    options={{
                        title: 'Хостинг',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingBackup"
                    component={checkAuth(HostingBackupScreen)}
                    options={{
                        title: 'Хостинг | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingBackupResources"
                    component={checkAuth(HostingBackupResourcesScreen)}
                    options={{
                        title: 'Хостинг | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingBackupContainer"
                    component={checkAuth(HostingBackupContainerScreen)}
                    options={{
                        title: 'Хостинг | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingBackupVirtualhost"
                    component={checkAuth(HostingBackupVirtualhostScreen)}
                    options={{
                        title: 'Хостинг | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingBackupDbms"
                    component={checkAuth(HostingBackupDbmsScreen)}
                    options={{
                        title: 'Хостинг | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingBackupConfiguration"
                    component={checkAuth(HostingBackupConfigurationScreen)}
                    options={{
                        title: 'Хостинг | Мастер восстановления',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingCreateContainer"
                    component={checkAuth(HostingCreateContainerStackScreen)}
                    options={{
                        title: 'Хостинг | Создание контейнера',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingContainer"
                    component={checkAuth(HostingContainerScreen)}
                    options={{
                        title: 'Хостинг | Контейнер',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingCreateVirtualhost"
                    component={checkAuth(HostingCreateVirtualhost)}
                    options={{
                        title: 'Хостинг | Создание нового сайта',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingChangeTariff"
                    component={checkAuth(HostingChangeTariffScreen)}
                    options={{
                        title: 'Хостинг | Изменение тарифа',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingContainerVirtualhost"
                    component={checkAuth(HostingVirtualhostScreen)}
                    options={{
                        title: 'Хостинг | Сайт',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="HostingDbmsAccess"
                    component={checkAuth(HostingDbmsAccessStackScreen)}
                    options={{
                        title: 'Хостинг | Базы данных',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="RestorePassword"
                    component={checkAuth(RestorePasswordScreen, false)}
                    options={{
                        title: 'Восстановление пароля',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="RestorePasswordDone"
                    component={checkAuth(RestorePasswordDoneScreen, false)}
                    options={{
                        header: () => null,
                        title: 'Восстановление пароля',
                    }}
                />
                <Stack.Screen
                    name="RestorePasswordConfirm"
                    component={checkAuth(RestorePasswordConfirmScreen, false)}
                    options={{
                        header: () => null,
                        title: 'Восстановление пароля. Новый пароль',
                    }}
                />
                <Stack.Screen
                    name="RestorePasswordFail"
                    component={checkAuth(RestorePasswordFailScreen, false)}
                    options={{
                        header: () => null,
                        title: 'Восстановление пароля. Ссылка устарела',
                    }}
                />
                <Stack.Screen
                    name="AuthFail"
                    component={AuthFailScreen}
                    options={{
                        header: () => null,
                        title: 'Вход. Личный кабинет',
                    }}
                />
                <Stack.Screen
                    name="Auth"
                    component={checkAuth(AuthScreen, false, (navigation) => {
                        navigation.navigate('MainStack');
                    })}
                    options={{
                        header: () => null,
                        title: 'Вход. Личный кабинет',
                    }}
                />
                <Stack.Screen
                    name="OtpStack"
                    component={OtpScreen}
                    options={{
                        header: () => null,
                        title: 'Вход, проверка двухфакторной авторизации. Личный кабинет',
                    }}
                />
                <Stack.Screen
                    name="OtpFail"
                    component={OtpFail}
                    options={{
                        header: () => null,
                        title: 'Вход, проверка двухфакторной авторизации. Личный кабинет',
                    }}
                />
                <Stack.Screen
                    name="ChangePassword"
                    component={checkAuth(ChangePasswordScreen)}
                    options={{
                        title: 'Изменение пароля',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="PartnershipWithdrawal"
                    component={checkAuth(PartnershipWithdrawalScreen)}
                    options={{
                        title: 'Партнерская программа | Вывод средств',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="ChangeRequisites"
                    component={checkAuth(ChangeRequisitesScreen)}
                    options={{
                        title: 'Партнерская программа | Реквизиты для вывода средств',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="Mail"
                    component={checkAuth(MailScreen)}
                    options={{
                        title: 'Почта',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="MailPacket"
                    component={checkAuth(MailPacketScreen)}
                    options={{
                        title: 'Почта | Пакеты почты',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="MailDomain"
                    component={checkAuth(MailDomainScreen)}
                    options={{
                        title: 'Почта | Почтовый сервер',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="MailboxSettings"
                    component={checkAuth(MailboxSettingsScreen)}
                    options={{
                        title: 'Почта | Настройки',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="Transfer"
                    component={checkAuth(TransferScreen)}
                    options={{
                        title: 'Передача ресурсов',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="TransferAccept"
                    component={checkAuth(TransferAcceptScreen)}
                    options={{
                        title: 'Передача ресурсов | Принять',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="Monitoring"
                    component={checkAuth(MonitoringStackScreen)}
                    options={{
                        title: 'Мониторинг',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="MonitoringHosting"
                    component={checkAuth(MonitoringHostingStackScreen)}
                    options={{
                        title: 'Мониторинг | Хостинг',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="MonitoringCvds"
                    component={checkAuth(MonitoringCvdsStackScreen)}
                    options={{
                        title: 'Мониторинг | VDS',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="MonitoringMail"
                    component={checkAuth(MonitoringMailStackScreen)}
                    options={{
                        title: 'Мониторинг | Почта',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="SSL"
                    component={checkAuth(SslScreen)}
                    options={{
                        title: 'SSL-сертификаты',
                        header: () => null,
                    }}
                />
                <Stack.Screen
                    name="SSLDetail"
                    component={checkAuth(SSLDetailScreen)}
                    options={{
                        title: 'SSL-сертификаты',
                        header: () => null,
                    }}
                />
            </Stack.Navigator>
        </NavigationContainer>
    );
}

export default AppNavigation;
