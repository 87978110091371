import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { dbms } from '../constants/hosting';
import DbmsAccess from '../components/Hosting/Virtualhost/DbmsAccess';
import { NavigationProps } from '../types';
import { screenHeaderStyles } from '../utils/styles';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingDbmsAccessStack = createStackNavigator();

function HostingDbmsAccessStackScreen(props) {
    const {
        navigation,
        dbmsAccount,
        virtualhost,
    } = props;
    const insets = useSafeAreaInsets();

    return <HostingDbmsAccessStack.Navigator>
        <HostingDbmsAccessStack.Screen name="HostingDbmsAccess" component={DbmsAccess}
            options={{
                title: 'Хостинг | Базы данных',
                headerTitle: () => <ScreenHeaderTitle title={`Реквизиты ${dbms[dbmsAccount.type]}`} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.goBack(),
                    backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
                headerStyle: {
                    ...screenHeaderStyles.headerStyle,
                    opacity: virtualhost && !virtualhost.enabled ? 0.3 : 1,
                    backgroundColor: virtualhost && !virtualhost.enabled ? 'rgba(69, 85, 98, 0.05)' : '#f5f5f5'
                },
                headerTintColor: '#ffffff',
            }} />
    </HostingDbmsAccessStack.Navigator>;
}

HostingDbmsAccessStackScreen.propTypes = {
    virtualhost: PropTypes.shape({
        enabled: PropTypes.bool,
    }),
    dbmsAccount: PropTypes.shape({
        type: PropTypes.string,
    }),
    navigation: NavigationProps
};

const mapStateToProps = (state) => ({
    dbmsAccount: state.hosting.virtualhost.dbms_account,
    virtualhost: state.hosting.virtualhost
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HostingDbmsAccessStackScreen);
