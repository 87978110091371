import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchCVDSBackups,
    fetchCVDSBackupDates,
    restoreFromBackupUpdateParams
} from '../../actions/cvds/actions';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreBackupWithDatesLoader from '../../components/General/RestoreBackupLoader/RestoreBackupWithDatesLoader';
import RestoreBackupMethodsStep from '../../components/CVDS/CvdsBackup/RestoreBackupMethodsStep';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CvdsBackupMethodsStack = createStackNavigator();

function CvdsBackupMethodsStackScreen(props) {
    const { route } = props;
    const {
        currentVM,
        currentVolume,
        backupMethods
    } = useSelector((state) => state.cvdsRestoreFromBackup);
    const {
        backups,
        backupDates,
        backupDatesInProgress,
    } = useSelector((state) => state.cvdsBackups);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();
    const vmId = Number.parseInt(route.params.vmId, 10);
    const volumeId = Number.parseInt(route.params.volumeId, 10);

    useFocusEffect(React.useCallback(() => {
        dispatch(fetchCVDSBackups());
    }, []));
    useEffect(() => {
        if (currentVM == null && vmId != null && volumeId != null && backups) {
            const currentVM = backups.entities.find((item) => item.id === vmId);
            const currentVolume = currentVM.resources.find(
                (item) => item.id === volumeId,
            );
            dispatch(
                restoreFromBackupUpdateParams({
                    currentVolume,
                    currentVM,
                }),
            );
            dispatch(
                fetchCVDSBackupDates(
                    currentVolume.resource_url,
                    (backupDates) => {
                        if (backupDates.entities.length === 0) {
                            return;
                        }
                        const {
                            backup_date,
                            resource_name,
                            resource_type,
                            methods,
                        } = backupDates.entities[0];
                        dispatch(
                            restoreFromBackupUpdateParams({
                                backupDate: backup_date,
                                resourceName: resource_name,
                                resourceType: resource_type,
                                backupMethod: methods[0],
                                backupMethods: methods,
                            }),
                        );
                    },
                ),
            );
        }
    }, [backups]);
    return <CvdsBackupMethodsStack.Navigator>
        <CvdsBackupMethodsStack.Screen name="CVDSBackupMethodsScreen"
            component={
                (backupDates == null || backupDatesInProgress || backupMethods == null)
                    ? RestoreBackupWithDatesLoader : RestoreBackupMethodsStep}
            options={{
                title: 'Облачные VDS | Мастер восстановления',
                headerTitle: () => <ScreenHeaderTitle
                    title={(currentVolume && currentVolume.name) || 'Идет загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('CVDSBackupDisks', { vmId });
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </CvdsBackupMethodsStack.Navigator>;
}

CvdsBackupMethodsStackScreen.propTypes = {
    route: PropTypes.object,
};

export default CvdsBackupMethodsStackScreen;
