import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import SendInvoiceToEmailScreen from '../components/Finance/FinanceDocumentsTab/SendInvoiceToEmailScreen';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const SendInvoiceToEmailStack = createStackNavigator();

function SendInvoiceToEmailStackScreen(props) {
    const { route, navigation } = props;
    const insets = useSafeAreaInsets();

    return <SendInvoiceToEmailStack.Navigator>
        <SendInvoiceToEmailStack.Screen name="SendInvoiceToEmailScreen"
            initialParams={{
                invoiceId: route.params.invoiceId
            }}
            component={SendInvoiceToEmailScreen} options={{
                title: 'Финансы | Отправка счета на Email',
                headerTitle: () => <ScreenHeaderTitle title='Отправка счета на Email' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        } else {
                            navigation.navigate('MainStack', {
                                screen: 'Finance',
                                params: {
                                    screen: 'FinanceTabs', params: { screen: 'FinanceDocuments' }
                                }
                            });
                        }
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </SendInvoiceToEmailStack.Navigator>;
}

export default SendInvoiceToEmailStackScreen;
