import React from 'react';
import { useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import {
    Image,
    Platform,
    TouchableOpacity,
    StyleSheet
} from 'react-native';

import { menuBackIcon, logoutIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import { logout } from '../actions/user/actions';
import { fetchAccount } from '../actions/account/actions';
import AccountScreen from '../components/Account/AccountScreen/AccountScreen';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const AccountStack = createStackNavigator();

function AccountStackScreen() {
    const insets = useSafeAreaInsets();
    const dispatch = useDispatch();
    const navigation = useNavigation();

    useFocusEffect(React.useCallback(() => {
        dispatch(fetchAccount());
        return () => {};
    }));

    return <AccountStack.Navigator>
        <AccountStack.Screen name="AccountScreen" component={AccountScreen} options={{
            title: 'Аккаунт',
            headerTitle: () => <ScreenHeaderTitle title='Аккаунт' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.navigate('General'),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerRight: () => (
                <TouchableOpacity
                    style={styles.logoutWrapper}
                    onPress={() => dispatch(
                        logout(() => {
                            if (Platform.OS === 'web') {
                                document.location = '/auth';
                            } else {
                                navigation.navigate('Auth');
                            }
                        })
                    )}
                >
                    <Image source={logoutIcon} style={styles.logoutIcon} />
                </TouchableOpacity>
            ),
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </AccountStack.Navigator>;
}

const styles = StyleSheet.create({
    logoutWrapper: {
        marginRight: 18,
    },
    logoutIcon: {
        width: 16,
        height: 16,
    },
});

export default AccountStackScreen;
