import React from 'react';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import PromisedPaymentScreen from '../components/Finance/PromisedPaymentScreen/PromisedPaymentScreen';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const FinancePromisedPaymentStack = createStackNavigator();

function FinancePromisedPaymentStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <FinancePromisedPaymentStack.Navigator>
        <FinancePromisedPaymentStack.Screen name="PromisedPayment"
            component={PromisedPaymentScreen} options={{
                title: 'Финансы | Обещанный платеж',
                headerTitle: () => <ScreenHeaderTitle title='Обещанный платеж' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.goBack(),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </FinancePromisedPaymentStack.Navigator>;
}

export default FinancePromisedPaymentStackScreen;
