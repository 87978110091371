import React, { useEffect } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { merge } from '@netangels/core/utils/functions';

import { useDispatch } from 'react-redux';
import VMSummary from './VMSummary';
import VolumesTab from './VolumesTab';
import VMAccess from './VMAccess';
import VMNetwork from './VMNetwork';
import { tabBarOptions } from '../../utils/tab-bar';
import { vmToInit } from '../../actions/cvds/actions';

const CVDSVMTab = createMaterialTopTabNavigator();

function VMPage() {
    const dispatch = useDispatch();
    useEffect(
        () => () => {
            dispatch(vmToInit());
        },
        []
    );
    return (
        <CVDSVMTab.Navigator
            tabBarOptions={merge(tabBarOptions, {
                tabStyle: {
                    width: 80,
                },
            })}
        >
            <CVDSVMTab.Screen
                name="VMSummary"
                component={VMSummary}
                options={{
                    tabBarLabel: 'Общее',
                    title: 'Облачные VDS | Виртуальная машина | Общее',
                }}
            />
            <CVDSVMTab.Screen
                name="CVDSVMDisk"
                component={VolumesTab}
                options={{
                    tabBarLabel: 'Диски',
                    title: 'Облачные VDS | Виртуальная машина | Диски',
                }}
            />
            <CVDSVMTab.Screen
                name="VMAccess"
                component={VMAccess}
                options={{
                    tabBarLabel: 'Доступ',
                    title: 'Облачные VDS | Виртуальная машина | Доступ',
                }}
            />
            <CVDSVMTab.Screen
                name="VMNetwork"
                component={VMNetwork}
                options={{
                    tabBarLabel: 'Сеть/IP',
                    title: 'Облачные VDS | Виртуальная машина | Сеть',
                }}
            />
        </CVDSVMTab.Navigator>
    );
}

export default VMPage;
