import React from 'react';
import PropTypes from 'prop-types';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import CVDSScreen from '../components/CVDS/CVDSPage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CVDSStack = createStackNavigator();

function CVDSStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <CVDSStack.Navigator>
        <CVDSStack.Screen name="CVDSStack" component={CVDSScreen} options={{
            title: 'Облачные VDS | Виртуальные машины',
            headerTitle: () => <ScreenHeaderTitle title='VDS' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.navigate('MainStack'),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </CVDSStack.Navigator>;
}

CVDSStackScreen.propTypes = {
    navigation: PropTypes.object
};

export default CVDSStackScreen;
