import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import HostingChangeTariff from '../components/Hosting/ContainerTariffTab/HostingChangeTariff';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const hostingChangeTariffStack = createStackNavigator();

function hostingChangeTariffStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <hostingChangeTariffStack.Navigator>
        <hostingChangeTariffStack.Screen
            name="HostingChangeTariff" component={HostingChangeTariff} options={{
                title: 'Хостинг | Изменение тарифа',
                headerTitle: () => <ScreenHeaderTitle title='Изменение тарифа' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('HostingContainer'),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </hostingChangeTariffStack.Navigator>;
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(hostingChangeTariffStackScreen);
