import React from 'react';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import FinancePayment from '../components/Finance/FinancePayment/FinancePayment';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const FinancePaymentStack = createStackNavigator();

function FinancePaymentStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <FinancePaymentStack.Navigator>
        <FinancePaymentStack.Screen name="PaymentStack" component={FinancePayment} options={{
            title: 'Финансы | Пополнение счета',
            headerTitle: () => <ScreenHeaderTitle title='Пополнение счета' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('MainStack');
                    }
                },
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </FinancePaymentStack.Navigator>;
}

export default FinancePaymentStackScreen;
