import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyleSheet, Text, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {
    createStackNavigator,
    HeaderBackButton,
} from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import { markUserNotifications } from '../actions/notifications/actions';
import NotificationsScreen from '../components/Notifications/NotificationsScreen';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const NotificationStack = createStackNavigator();

const styles = StyleSheet.create({
    notificationsCount: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 8,
        paddingLeft: 8,
        backgroundColor: '#ffffff',
        borderRadius: 20,
        color: '#0f79d5',
        fontSize: 9,
        fontWeight: '500',
        lineHeight: 9,
        marginRight: 20,
    },
});

function NotificationScreen() {
    return <NotificationsScreen />;
}

function NotificationStackScreen(props) {
    const { navigation, notifications, markUserNotifications } = props;
    const insets = useSafeAreaInsets();

    let markMessageTimeoutId = null;
    const deltaMarkTimeout = 2 * 1000;
    const [isNeedMark, setIsNeedMark] = React.useState(false);

    useFocusEffect(
        React.useCallback(() => {
            markMessageTimeoutId = setTimeout(() => {
                setIsNeedMark(true);
            }, deltaMarkTimeout);
            return () => {
                if (markMessageTimeoutId != null) {
                    clearInterval(markMessageTimeoutId);
                    markMessageTimeoutId = null;
                }
                if (isNeedMark) {
                    const unreadEntities = notifications.entities.filter(
                        (item) => item.is_read === false
                    );
                    if (unreadEntities.length > 0) {
                        markUserNotifications(unreadEntities);
                    }
                }
            };
        })
    );

    return (
        <NotificationStack.Navigator>
            <NotificationStack.Screen
                name="Notification"
                component={NotificationScreen}
                options={{
                    title: 'Уведомления',
                    headerTitle: () => <ScreenHeaderTitle title="Уведомления" />,
                    headerLeft: (props) => (
                        <HeaderBackButton
                            {...{
                                ...props,
                                onPress: () => navigation.navigate('MainStack'),
                                backImage: () => (
                                    <Image
                                        source={menuBackIcon}
                                        style={{ width: 16, height: 16 }}
                                    />
                                ),
                            }}
                        />
                    ),
                    headerRight: () => notifications
            && notifications.unread > 0 && (
                        <Text style={styles.notificationsCount}>
                            {notifications.unread}{' '}
                            {notifications.unread > 1 ? 'новых' : 'новое'}
                        </Text>
                    ),
                    headerStatusBarHeight: insets.top,
                    ...screenHeaderStyles,
                }}
            />
        </NotificationStack.Navigator>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    notifications: state.notifications.notifications,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        markUserNotifications,
    },
    dispatch
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationStackScreen);
