import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import {
    containerInitial,
    containerLimits,
    fetchUserContainer,
    fetchContainerCalculation,
    containerListCapabilities,
    fetchContainerVirtualhosts,
} from '../actions/hosting/actions';
import { screenHeaderStyles } from '../utils/styles';
import ContainerTabs from '../components/Hosting/ContainerTabs';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingContainerStack = createStackNavigator();

function HostingContainerStackScreen(props) {
    const {
        route,
        limits,
        navigation,
        container,
        virtualhosts,
        containerName,
        containerLimits,
        containerInitial,
        limitsInProgress,
        fetchUserContainer,
        virtualhostsInProgress,
        containerFetchInProgress,
        fetchContainerCalculation,
        fetchContainerVirtualhosts,
    } = props;
    const insets = useSafeAreaInsets();

    useFocusEffect(useCallback(() => {
        if (
            (!containerFetchInProgress && container == null)
                || (container.id !== Number.parseInt(route.params.containerId, 10))
        ) {
            fetchUserContainer(route.params.containerId, () => {
                fetchContainerCalculation(route.params.containerId);
            });
        }
        if (container != null && !limitsInProgress && limits == null) {
            containerLimits(route.params.containerId);
        }
        if (container != null && !virtualhostsInProgress && virtualhosts == null) {
            fetchContainerVirtualhosts(route.params.containerId);
        }
    }, [container, virtualhosts, limits]));

    return <HostingContainerStack.Navigator>
        <HostingContainerStack.Screen name="HostingContainerStack" component={ContainerTabs}
            options={{
                title: `Хостинг | Контейнер ${containerName || ''}`,
                headerTitle: () => <ScreenHeaderTitle title={containerName || 'Идет загрузка...'} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        navigation.navigate('Hosting');
                        containerInitial();
                    },
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
                headerStyle: {
                    ...screenHeaderStyles.headerStyle,
                    opacity: container && !container.enabled ? 0.3 : 1,
                    backgroundColor: container && !container.enabled ? 'rgba(69, 85, 98, 0.05)' : '#f5f5f5'
                },
            }} />
    </HostingContainerStack.Navigator>;
}

HostingContainerStackScreen.propTypes = {
    container: PropTypes.shape({
        id: PropTypes.number,
        enabled: PropTypes.bool,
    }),
    limits: PropTypes.shape({}),
    virtualhosts: PropTypes.shape({}),
    containerName: PropTypes.string,
    containerLimits: PropTypes.shape({}),
    containerInitial: PropTypes.func,
    limitsInProgress: PropTypes.bool,
    fetchUserContainer: PropTypes.func,
    virtualhostsInProgress: PropTypes.bool,
    containerFetchInProgress: PropTypes.bool,
    fetchContainerCalculation: PropTypes.func,
    fetchContainerVirtualhosts: PropTypes.func,
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    container: state.hosting.container,
    containerName: state.hosting.container && state.hosting.container.name,
    limits: state.hosting.container && state.hosting.container.limits,
    limitsInProgress: state.hosting.container && state.hosting.container.limitsInProgress,
    virtualhosts: state.hosting.virtualhosts,
    virtualhostsInProgress: state.hosting.virtualhostsInProgress,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    containerInitial,
    containerLimits,
    fetchUserContainer,
    containerListCapabilities,
    fetchContainerCalculation,
    fetchContainerVirtualhosts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HostingContainerStackScreen);
