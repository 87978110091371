import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import { View, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import useWebsockets from '../hooks/useWebsockets';
import { connectToWebsocketWithDelay } from '../utils/websockets';

import {
    initCertificate,
    certificateDetail,
    wsCertificateUpdate
} from '../actions/certificates/actions';
import {
    editBlueIcon,
    tweakIcon,
    transferIcon,
    trashIcon,
    returnIcon,
    prolongBlueIcon,
} from '../icons/index';
import { screenHeaderStyles } from '../utils/styles';
import SslDetail from '../components/SSL/SslDetail';
import Modal from '../components/General/Modal/Modal';
import { ModalContext } from '../contexts/ModalContext';
import Action from '../components/General/Actions/Action';
import Actions from '../components/General/Actions/Actions';
import Separator from '../components/General/Separator/Separator';
import SslResourceLoader from '../components/SSL/SslResourceLoader';
import ChangeRenewalModal from '../components/SSL/Modals/ChangeRenewalModal';
import DeleteSslModal from '../components/SSL/Modals/DeleteSslModal';
import ProlongModal from '../components/SSL/Modals/ProlongModal';
import RenameModal from '../components/SSL/Modals/RenameModal';

const SslDetailStack = createStackNavigator();

function SslDetailStackScreen(props) {
    const {
        route,
        navigation,
    } = props;
    const insets = useSafeAreaInsets();
    const { closeModal } = useContext(ModalContext);
    const { certificate, certificateInProgress } = useSelector((state) => state.certificates);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const ws = useWebsockets();
    let sslSubscriber = null;

    const [isRenameModalShowed, setRenameModalShowed] = React.useState(false);
    const [isProlongModalShowed, setProlongModalShowed] = React.useState(false);
    const [isChangeRenewalModalShowed, setChangeRenewalModalShowed] = React.useState(false);
    const [isDeleteModalShowed, setDeleteModalShowed] = React.useState(false);
    useEffect(() => {
        if (!certificateInProgress) {
            dispatch(certificateDetail(route.params.certificateId));
        }
        const connectionState = connectToWebsocketWithDelay(() => {
            if (sslSubscriber == null) {
                const channelName = getChannel(userChannelName, user.login, true);
                sslSubscriber = ws.subscriber('certificates', channelName);
                sslSubscriber
                    .on('certificate.update', (data) => {
                        dispatch(wsCertificateUpdate(data));
                    })
                    .on('certificate.refresh', (data) => {
                        dispatch(wsCertificateUpdate(data));
                    });
            }
        });
        return () => {
            connectionState.cancel();
            if (sslSubscriber != null) {
                ws.unsubscribe(sslSubscriber);
                sslSubscriber = null;
            }
        };
    }, []);
    const needShowPublisher = certificate && certificate.publisher !== 'Unknown';
    const hasManualRenewal = needShowPublisher && certificate.publisher !== 'LeaderSSL'
        && dayjs() >= dayjs(certificate.not_after).subtract(1, 'months');
    return <SslDetailStack.Navigator>
        <SslDetailStack.Screen name="SslDetailScreen"
            component={
                (certificateInProgress || certificate == null) ? SslResourceLoader : SslDetail
            }
            initialParams={{ height: 77, count: 3 }}
            options={{
                title: `SSL-сертификаты | ${(certificate && certificate.name) || 'Загрузка...'}`,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => {
                        navigation.navigate('SSL');
                        dispatch(initCertificate());
                    }
                }} />,
                headerRight: () => <View>
                    <Actions modalId='certificate-actions' icon={tweakIcon}
                        iconWidth={16} iconHeight={16}
                        additionalButtonStyles={[styles.actions]}>
                        <Action icon={editBlueIcon}
                            title='Переименовать'
                            onPress={() => {
                                closeModal();
                                setRenameModalShowed(true);
                            }} />
                        <Separator />
                        {hasManualRenewal && <React.Fragment>
                            <Action icon={returnIcon}
                                title={certificate && certificate.state !== 'Expired'
                                    ? 'Продлить'
                                    : 'Перевыпустить'}
                                onPress={() => {
                                    closeModal();
                                    setProlongModalShowed(true);
                                }} />
                            <Separator />
                        </React.Fragment>}
                        <Action icon={prolongBlueIcon}
                            title={`${certificate && certificate.is_renewal ? 'Выключить' : 'Включить'} автопродление`}
                            onPress={() => {
                                closeModal();
                                setChangeRenewalModalShowed(true);
                            }} />
                        <Separator />
                        <Action icon={transferIcon}
                            title='Передать ресурс'
                            onPress={() => {
                                closeModal();
                                navigation.navigate('Transfer', {
                                    id: `${certificate.id}`,
                                    service: 'certificates'
                                });
                            }} />
                        <Separator />
                        <Action icon={trashIcon}
                            title='Удалить сертификат'
                            textStyles={[{ color: '#D51010' }]}
                            onPress={() => {
                                closeModal();
                                setDeleteModalShowed(true);
                            }}/>
                    </Actions>
                    <Modal isModalShowed={isRenameModalShowed}
                        component={<RenameModal
                            certificate={certificate}
                            onClose={() => setRenameModalShowed(false)}/>}
                        onClose={() => setRenameModalShowed(false)} />
                    <Modal isModalShowed={isProlongModalShowed}
                        component={<ProlongModal
                            certificate={certificate}
                            needReorder={certificate && certificate.state === 'Expired'}
                            onClose={() => setProlongModalShowed(false)}/>}
                        onClose={() => setProlongModalShowed(false)} />
                    <Modal isModalShowed={isChangeRenewalModalShowed}
                        component={<ChangeRenewalModal
                            certificate={certificate}
                            onClose={() => setChangeRenewalModalShowed(false)}/>}
                        onClose={() => setChangeRenewalModalShowed(false)} />
                    <Modal isModalShowed={isDeleteModalShowed}
                        component={<DeleteSslModal
                            certificate={certificate}
                            onClose={() => setDeleteModalShowed(false)}/>}
                        onClose={() => setDeleteModalShowed(false)} />
                </View>,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
                headerStyle: {
                    ...screenHeaderStyles.headerStyle,
                    borderBottomWidth: 0
                }
            }}/>
    </SslDetailStack.Navigator>;
}

const styles = StyleSheet.create({
    actions: {
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginRight: 9
    }
});

SslDetailStackScreen.propTypes = {
    route: PropTypes.shape({}),
    navigation: PropTypes.object,
};

export default SslDetailStackScreen;
