import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { merge } from '@netangels/core/utils/functions';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import MailDomainsTab from './DomainsTab';
import MailStatisticsTab from './StatisticsTab';
import { tabBarOptions } from '../../utils/tab-bar';
import { initSearchDomains } from '../../actions/mail/actions';

const MailTab = createMaterialTopTabNavigator();

function MailTabs() {
    const dispatch = useDispatch();

    useEffect(() => () => dispatch(initSearchDomains()), []);
    return <MailTab.Navigator
        tabBarOptions={merge(tabBarOptions, {
            tabStyle: {}
        })}>
        <MailTab.Screen name="MailDomains" component={MailDomainsTab} options={{
            title: 'Почта - Домены',
            tabBarLabel: 'Домены'
        }} listeners={{
            tabPress: () => dispatch(initSearchDomains()),
        }}/>
        <MailTab.Screen name="MailStatistics" component={MailStatisticsTab} options={{
            title: 'Почта - Статистика и лимиты',
            tabBarLabel: 'Статистика и лимиты',
        }}/>
    </MailTab.Navigator>;
}

export default MailTabs;
