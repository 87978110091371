import React from 'react';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import FinanceForecast from '../components/Finance/Forecast/Forecast';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const FinanceForecastStack = createStackNavigator();


function FinanceForecastStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <FinanceForecastStack.Navigator>
        <FinanceForecastStack.Screen name="Forecast" component={FinanceForecast} options={{
            title: 'Финансы | Ожидаемые списания',
            headerTitle: () => <ScreenHeaderTitle title='Ожидаемые списания' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.goBack(),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </FinanceForecastStack.Navigator>;
}

export default FinanceForecastStackScreen;
