import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import {
    initMailboxes,
    fetchMailboxInfo,
    fetchUserMailInfo
} from '../actions/mail/actions';
import { screenHeaderStyles } from '../utils/styles';
import MailboxSettingsPage from '../components/Mail/MailboxSettingsPage';
import MailResourceLoader from '../components/Mail/MailResourceLoader';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const MailboxSettingsStack = createStackNavigator();

function MailboxSettings(props) {
    const {
        route,
        navigation,
    } = props;
    const dispatch = useDispatch();

    const {
        mailbox,
        mailboxInProgress,
        userInfo,
        userInfoInProgress,
    } = useSelector((state) => state.mail);
    const insets = useSafeAreaInsets();

    useEffect(() => {
        dispatch(fetchMailboxInfo(route.params.mailboxId));
        if (!userInfoInProgress && userInfo == null) {
            dispatch(fetchUserMailInfo());
        }
        return () => dispatch(initMailboxes());
    }, [route]);
    return <MailboxSettingsStack.Navigator>
        <MailboxSettingsStack.Screen name="MailboxSettingsStack" component={
            (mailboxInProgress || mailbox == null) ? MailResourceLoader : MailboxSettingsPage
        }
        options={{
            title: 'Почта | Настройки',
            headerTitle: () => <ScreenHeaderTitle title={(mailbox && mailbox.name) || 'Идет загрузка...'} />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.navigate('MailDomain', {
                    domainId: route.params.domainId
                }),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </MailboxSettingsStack.Navigator>;
}

MailboxSettings.propTypes = {
    route: PropTypes.shape({}),
    navigation: PropTypes.shape({}),
};

export default MailboxSettings;
