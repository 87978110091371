import React, { useEffect } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreConfigurationStep from '../../components/Hosting/HostingBackup/RestoreConfigurationStep';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingBackupConfigurationStack = createStackNavigator();

function HostingBackupConfigurationStackScreen() {
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    return <HostingBackupConfigurationStack.Navigator>
        <HostingBackupConfigurationStack.Screen name="HostingBackupConfigurationScreen"
            component={RestoreConfigurationStep}
            options={{
                title: 'Мастер восстановления',
                headerTitle: () => <ScreenHeaderTitle title='Подтверждение' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.goBack(),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </HostingBackupConfigurationStack.Navigator>;
}

export default HostingBackupConfigurationStackScreen;
