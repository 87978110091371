import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import {
    initResources,
    checkResources,
    changeTransferQuery,
} from '../actions/transfer/actions';
import { screenHeaderStyles } from '../utils/styles';
import { navigationHelper } from '../utils/transfer';
import { resourceTypesList, servicesResourceNameForTransferMap } from '../constants/general';
import TransferLoader from '../components/Transfer/TransferLoader';
import TransferPage from '../components/Transfer/TransferPage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const TransferStack = createStackNavigator();
const formId = 'CheckResourcesFormId';

function TransferStackScreen(props) {
    const { route } = props;
    const {
        currentResources,
        checkResourcesInProgress
    } = useSelector((state) => state.transfer);
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();
    const currentParams = route.state && route.state.routes[0].params
        ? route.state.routes[0].params
        : route.params;
    const { id, service } = currentParams;
    useFocusEffect(React.useCallback(() => {
        if (!checkResourcesInProgress) {
            const resourcesArray = id.split(',').map((id) => ({
                resource_id: id,
                resource_type: resourceTypesList.find(
                    (r) => r.service === service,
                ).resource_type,
            }));
            dispatch(checkResources(formId, resourcesArray));
            dispatch(changeTransferQuery({ resourcesArray, service }));
        }
        return dispatch(initResources());
    }, [currentParams]));

    return <TransferStack.Navigator>
        <TransferStack.Screen name="TransferScreen"
            component={
                (checkResourcesInProgress || currentResources == null)
                    ? TransferLoader : TransferPage}
            options={{
                title: 'Передача ресурсов',
                headerTitle: () => <ScreenHeaderTitle
                    title={`Передача ${servicesResourceNameForTransferMap[service]}`} />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigationHelper(service, navigation, { id }),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </TransferStack.Navigator>;
}

TransferStackScreen.propTypes = {
    route: PropTypes.object,
};

export default TransferStackScreen;
