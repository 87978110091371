import React from 'react';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import { screenHeaderStyles } from '../utils/styles';
import ChangePassword from '../components/Account/AccountAccessSecurity/AccountChangePassword';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const ChangePasswordStack = createStackNavigator();

function ChangePasswordStackScreen(props) {
    const { navigation } = props;
    const insets = useSafeAreaInsets();

    return <ChangePasswordStack.Navigator>
        <ChangePasswordStack.Screen name="ChangePasswordStack" component={ChangePassword} options={{
            title: 'Изменение пароля',
            headerTitle: () => <ScreenHeaderTitle title='Изменение пароля' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    } else {
                        navigation.navigate('MainStack', { screen: 'Account' });
                    }
                },
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </ChangePasswordStack.Navigator>;
}

export default ChangePasswordStackScreen;
