import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Image } from 'react-native';

import { getChannel } from '@netangels/core/utils/websockets';
import { userChannelName } from '@netangels/core/constants/websockets';

import useWebsockets from '../hooks/useWebsockets';
import { screenHeaderStyles } from '../utils/styles';
import { menuBackIcon } from '../icons';

import PartnershipTabs from './partnershipTabs';
import { connectToWebsocketWithDelay } from '../utils/websockets';
import PartnershipLaunchPage from '../components/Partnership/PartnershipLaunchPage';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

import {
    wsEntriesFetch,
    partnershipData,
    fetchStatistics,
    wsRegisterClient,
    wsParthershipUpdateBalance,
    wsParthershipWithdrawalUntreatedUpdate,
} from '../actions/partnership/actions';

const PartnershipStack = createStackNavigator();

function PartnershipStackScreen(props) {
    const {
        user,
        isPartner,
        statistics,
        navigation,
        partnership,
        wsEntriesFetch,
        partnershipData,
        fetchStatistics,
        wsRegisterClient,
        wsParthershipUpdateBalance,
        wsParthershipWithdrawalUntreatedUpdate,
        partnershipInProgress,
    } = props;
    const insets = useSafeAreaInsets();
    const ws = useWebsockets();
    let partnershipSubscriber = null;

    useFocusEffect(useCallback(() => {
        if (!partnershipInProgress && partnership == null) {
            partnershipData();
        }
        if (!statistics.inProgress && statistics.statistics == null) {
            fetchStatistics();
        }
        const connectionState = connectToWebsocketWithDelay(() => {
            if (partnershipSubscriber == null) {
                const channelName = getChannel(userChannelName, user.login, true);
                partnershipSubscriber = ws.subscriber('finances', channelName);
                partnershipSubscriber
                    .on('partner.balance_changed', (data) => {
                        wsParthershipUpdateBalance(data.new_balance);
                    })
                    .on('partner.withdrawal_added', (data) => {
                        wsParthershipWithdrawalUntreatedUpdate(data.has_untreated_withdrawal);
                    })
                    .on('partner.withdrawal_done', (data) => {
                        wsParthershipWithdrawalUntreatedUpdate(data.has_untreated_withdrawal);
                    })
                    .on('partner.register_client', (client) => {
                        wsRegisterClient(client);
                    })
                    .on('partner.entry_created', (entry) => {
                        wsEntriesFetch(entry);
                    });
            }
        });
        return () => {
            connectionState.cancel();
            if (partnershipSubscriber != null) {
                ws.unsubscribe(partnershipSubscriber);
                partnershipSubscriber = null;
            }
        };
    }, []));

    return <PartnershipStack.Navigator>
        <PartnershipStack.Screen name="PartnershipTabs"
            component={isPartner ? PartnershipTabs : PartnershipLaunchPage}
            options={{
                title: 'Партнерская программа',
                headerTitle: () => <ScreenHeaderTitle title='Партнерская программа' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('General'),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }}/>
    </PartnershipStack.Navigator>;
}

PartnershipStackScreen.propTypes = {
    isPartner: PropTypes.bool,
    user: PropTypes.shape({
        login: PropTypes.string
    }),
    wsEntriesFetch: PropTypes.func,
    partnershipData: PropTypes.func,
    partnership: PropTypes.shape({}),
    wsRegisterClient: PropTypes.func,
    wsParthershipUpdateBalance: PropTypes.func,
    wsParthershipWithdrawalUntreatedUpdate: PropTypes.func,
    navigation: PropTypes.shape(),
    fetchStatistics: PropTypes.func,
    statistics: PropTypes.shape({
        inProgress: PropTypes.bool,
        statistics: PropTypes.shape({})
    }),
    partnershipInProgress: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    statistics: state.partnershipStatistics,
    partnership: state.partnership.partnership,
    isPartner: state.user.financeInfo.partner_v2,
    partnershipInProgress: state.partnership.partnershipInProgress
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    wsEntriesFetch,
    partnershipData,
    fetchStatistics,
    wsRegisterClient,
    wsParthershipUpdateBalance,
    wsParthershipWithdrawalUntreatedUpdate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipStackScreen);
