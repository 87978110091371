import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import { screenHeaderStyles } from '../../utils/styles';
import RestoreConfigurationStep from '../../components/CVDS/CvdsBackup/RestoreConfigurationStep';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const CvdsBackupConfigurationStack = createStackNavigator();

function CvdsBackupConfigurationStackScreen() {
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    return <CvdsBackupConfigurationStack.Navigator>
        <CvdsBackupConfigurationStack.Screen name="CvdsBackupConfigurationScreen"
            component={RestoreConfigurationStep}
            options={{
                title: 'Облачные VDS | Мастер восстановления',
                headerTitle: () => <ScreenHeaderTitle title='Подтверждение' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.goBack(),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </CvdsBackupConfigurationStack.Navigator>;
}

export default CvdsBackupConfigurationStackScreen;
