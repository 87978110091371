import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../../icons';
import {
    fetchUserContainers,
    restoreFromBackupToInit
} from '../../actions/hosting/actions';
import { screenHeaderStyles } from '../../utils/styles';
import MailResourceLoader from '../../components/Mail/MailResourceLoader';
import RestoreBackupBlock from '../../components/Hosting/HostingBackup/RestoreBackupBlock';
import ScreenHeaderTitle from '../../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingBackupStack = createStackNavigator();

function HostingBackupStackScreen() {
    const { containers, containersFetchInProgress } = useSelector(
        (state) => state.hosting,
    );
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const insets = useSafeAreaInsets();

    useFocusEffect(React.useCallback(() => {
        if (!containersFetchInProgress && containers === null) {
            dispatch(fetchUserContainers());
        }
    }, []));

    return <HostingBackupStack.Navigator>
        <HostingBackupStack.Screen name="HostingBackupScreen"
            component={
                (containers == null || containersFetchInProgress)
                    ? MailResourceLoader : RestoreBackupBlock}
            options={{
                title: 'Хостинг | Мастер восстановления',
                headerTitle: () => <ScreenHeaderTitle title='Мастер восстановления' />,
                headerLeft: (props) => <HeaderBackButton {...{
                    ...props,
                    onPress: () => navigation.navigate('MainStack'),
                    backImage: () => <Image
                        source={menuBackIcon} style={{ width: 16, height: 16 }} />
                }} />,
                headerStatusBarHeight: insets.top,
                ...screenHeaderStyles,
            }} />
    </HostingBackupStack.Navigator>;
}

export default HostingBackupStackScreen;
