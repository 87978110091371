import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import { Image } from 'react-native';

import { menuBackIcon } from '../icons';
import {
    fetchUserContainers
} from '../actions/hosting/actions';
import { screenHeaderStyles } from '../utils/styles';
import HostingScreen from '../components/Hosting/HostingScreen';
import ScreenHeaderTitle from '../components/General/ScreenHeaderTitle/ScreenHeaderTitle';

const HostingStack = createStackNavigator();

function HostingStackScreen(props) {
    const {
        containers,
        navigation,
        fetchUserContainers,
        containersFetchInProgress,
    } = props;
    const insets = useSafeAreaInsets();

    useFocusEffect(React.useCallback(() => {
        if (!containersFetchInProgress && containers === null) {
            fetchUserContainers();
        }
        return () => {};
    }, [containers]));

    return <HostingStack.Navigator>
        <HostingStack.Screen name="HostingScreen" component={HostingScreen} options={{
            title: 'Хостинг',
            headerTitle: () => <ScreenHeaderTitle title='Хостинг' />,
            headerLeft: (props) => <HeaderBackButton {...{
                ...props,
                onPress: () => navigation.navigate('MainStack'),
                backImage: () => <Image source={menuBackIcon} style={{ width: 16, height: 16 }} />
            }} />,
            headerStatusBarHeight: insets.top,
            ...screenHeaderStyles,
        }} />
    </HostingStack.Navigator>;
}

HostingStackScreen.propTypes = {
    containers: PropTypes.shape({}),
    fetchUserContainers: PropTypes.func,
    containersFetchInProgress: PropTypes.bool,
    navigation: PropTypes.object,
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    containers: state.hosting.containers,
    containersFetchInProgress: state.hosting.containersFetchInProgress,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserContainers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HostingStackScreen);
